import React from "react";
import { useSpring, animated  as a} from 'react-spring';

import Ebene1 from '../assets/404/Ebene1.inline.svg';
import Ebene2 from '../assets/404/Ebene2.inline.svg';
import Ebene3 from '../assets/404/Ebene3.inline.svg';
import Ebene4 from '../assets/404/Ebene4.inline.svg';
import Ebene5 from '../assets/404/Ebene5.inline.svg';
import Ebene6 from '../assets/404/Ebene6.inline.svg';
import Ebene7 from '../assets/404/Ebene7.inline.svg';
import Ebene8 from '../assets/404/Ebene8.inline.svg';

const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x: number, y: number) => `translate3d(${x / 230}px,${y / 230}px,0)`
const trans2 = (x, y) => `translate3d(${x / 325}px,${y / 325}px,0)`
const trans3 = (x, y) => `translate3d(${x / 320}px,${y / 320}px,0)`
const trans4 = (x, y) => `translate3d(${x / 350 * -1}px,${y / 620 * -1}px,0)`
const trans5 = (x, y) => `translate3d(${x / 310 * -1}px,${y / 410 * -1}px,0)`
const trans6 = (x, y) => `translate3d(${x / 255 * -1}px,${y / 405 * -1}px,0)`
const trans7 = (x, y) => `translate3d(${x / 220 * -1}px,${y / 400 * -1}px,0)`
const trans8 = (x, y) => `translate3d(${x / 195 * -1}px,${y / 395 * -1}px,0)`

function Icon404() {
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }));

    React.useEffect(() => {
        const onMouseMove = ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) });
        document.addEventListener("mousemove", onMouseMove);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        }
    });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 120 66"
    >
      <a.g id="Ebene8" style={{ transform: props.xy.to(trans8) }}>
         <Ebene8 />
      </a.g>
      <a.g id="Ebene7" style={{ transform: props.xy.to(trans7) }}>
        <Ebene7 />
      </a.g>
      <a.g id="Ebene6" style={{ transform: props.xy.to(trans6) }}>
        <Ebene6 />
      </a.g>
      <a.g id="Ebene5" style={{ transform: props.xy.to(trans5) }}>
        <Ebene5 />
      </a.g>
      <a.g id="Ebene4" style={{ transform: props.xy.to(trans4) }}>
        <Ebene4 />
      </a.g>
      <a.g id="Ebene3">
        <Ebene3 />
      </a.g>
      <a.g id="Ebene2" style={{ transform: props.xy.to(trans2) }}>
        <Ebene2 />
      </a.g>
      <a.g id="Ebene1" style={{ transform: props.xy.to(trans1) }}>
        <Ebene1 />
      </a.g>
    </svg>
  );
}

export default Icon404;
