import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero/Hero"
import Section from "../components/ui/Section/Section"
import Icon404 from "../components/Icon404"
import classnames from 'classnames';
import * as styles from './404.module.scss';
import ButtonAction from "../components/ui/Buttons/ButtonAction"
import SearchBox from "../components/Search/SearchBox"
import SearchResults from "../components/Search/SearchResults"
import { SearchContext } from "../components/Search/SearchContext"

const fourOfour = (data) => {

    const {dispatch} = React.useContext(SearchContext);

    React.useEffect(() => {
        let searchWords = data.location.pathname.split("/");
        searchWords = searchWords.filter(item => !item.includes("produkte"));
        dispatch({type: "SEARCH", query: searchWords.join(" ")})
    }, []);

    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
            ]}
            metadatas={{
                title: "Nichts gefunden | HIS Print & Service",
            }}
        >
            <Section>
                <div className="content">
                    <div className={classnames(["columns", styles.vcentered])}>
                        <div className="column is-one-third">
                            <h1>Wie können wir helfen?</h1>
                            <p>
                                Die angefragte Seite existiert noch nicht. Trotzdem können wir Ihnen mit unserem Know-How helfen. Durchsuchen Sie unsere Website oder Kontaktieren Sie uns direkt.
                            </p>
                            <ButtonAction to="/kontakt">Jetzt Kontaktieren</ButtonAction>
                        </div>
                        <div className="column">
                            <Icon404 />
                        </div>
                    </div>
                </div>
            </Section>
            <Hero color="light">
                <div className="content content-small">
                    <h2>Suchvorschläge für die angefragte Seite</h2>
                    <SearchBox />
                </div>
            </Hero>
            <Section size="extraSmall">
                
               

                <SearchResults />
                
            </Section>
        </Layout>
    )
}

export default fourOfour;